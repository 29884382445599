<script setup lang="ts">
const props = withDefaults(defineProps<{
    name?: string;
    filled?: boolean;
    fontControlled?: boolean;
    fallbackName?: string;
}>(), {
    name: '',
    fontControlled: true,
    fallbackName: '',
});
const icon = ref<string | undefined>();
async function loadIcon (name: string): Promise<string> {
    const iconName = name;
    const iconsImport = import.meta.glob('assets/icons/**/**.svg', { query: '?raw', import: 'default', eager: false });
    const rawIcon = await iconsImport[`/assets/icons/${iconName}.svg`]() as Promise<string>;
    return rawIcon;
}

function resolveIcon () {
    return Promise.resolve()
        .then(async () => {
            if (props.name) { icon.value = await loadIcon(props.name); } else if (props.fallbackName) { icon.value = await loadIcon(props.fallbackName); }
        }).catch(async () => {
            console.warn(`[RIcon] Icon '${props.name}' doesn't exist in 'assets/icons'`);
            if (props.fallbackName) { icon.value = await loadIcon(props.fallbackName); }
        }).catch(() => {
            console.error(`[RIcon] Fallback Icon '${props.fallbackName}' doesn't exist in 'assets/icons'`);
        });
}

await resolveIcon();

watch(() => props.name, () => resolveIcon());
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <span
        :class="{
            'nuxt-icon': fontControlled,
            'nuxt-icon--fill': !filled
        }"
        v-html="icon"
    />
</template>

<style>
.nuxt-icon svg {
  vertical-align: middle;
}

.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
  fill: currentColor !important;
}
</style>
